<template>
  <base-table
    title="Past notifications"
    :elements="notifications.data"
    :columns="columns"
  />
</template>
<script>
import BaseTable from "@/components/admin/BaseTable.vue";
import { Column } from "@/shared/types/column.class";

export default {
  name: "NotificationsTable",
  components: {
    BaseTable,
  },
  data() {
    return {
      columns: [
        new Column("recipient.email", "Recipient"),
        new Column("title", "Title"),
        new Column("message", "Message"),
        new Column("action_url", "Url"),
        new Column("from.email", "From"),
        new Column("created_at", "Sent at"),
      ],
    };
  },
  computed: {
    notifications() {
      return this.$store.getters["Notifications/all"];
    },
  },
  async created() {
    await this.$store.dispatch("Notifications/fetchAll");
  },
};
</script>
